import React from "react"
import MapContainer from "../components/map"
import SEO from "../components/seo"

const Poopmap = () => {
  return (
    <div>
      <SEO
        title="Nick Barth | Pooplog"
        description="Home of frontend developer Nick Barth, Amsterdam, NL"
      />
      <MapContainer />
    </div>
  )
}

export default Poopmap
